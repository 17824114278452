import React from "react"
import { graphql } from "gatsby"
import formatRelative from "date-fns/formatRelative"
import Img from "gatsby-image"
import { useMediaQuery } from "@react-hook/media-query"

import Layout from "../layouts"
import stripHtml from "../libs/strip-html"
import BackButton from "../components/back-button"
import BackgroundSection from "../components/background-section"

export default ({ data }) => {
  const mobile = useMediaQuery("only screen and (max-width: 769px)")
  const { post } = data
  return (
    <Layout title={post.title} description={stripHtml(post.excerpt)}>
      <BackgroundSection
        image={post.featured_media.localFile.childImageSharp.fluid}
        opacity="0.5"
      >
        <div className="container is-widescreen">
          <BackButton/>
          <div className="head">
            <h1 className="title is-1 has-text-primary">{post.title}</h1>
            <p className="subtitle is-4">
              {formatRelative(new Date(post.date), new Date())}
            </p>
          </div>
        </div>
        {mobile && <Img fluid={post.featured_media.localFile.childImageSharp.fluid} fadeIn/>}
        <div className="container is-widescreen">
          <div className="content" dangerouslySetInnerHTML={{ __html: post.content }}/>
        </div>
      </BackgroundSection>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        post: wordpressPost(slug: {eq: $slug}) {
            id: wordpress_id
            title
            content
            date
            excerpt
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(
                            maxWidth: 2000
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`